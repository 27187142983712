<template>
  <main>
    <section class="list">
      <div class="container">
        <h1>Ceník</h1>
        <div class="row">
          <div class="col-md-12 columned">
            <div class="list-item">
              <h2>Kosmetika</h2>
              <ul>
                <li>
                  Základní kosmetické ošetření dle typu pleti, cca 60min
                  <label>950,-Kč</label>
                </li>
                <li>
                  Základní kosmetické ošetření dle typu pleti, cca 60min
                  (Senioři 70+)<label>650,-Kč</label>
                </li>
                <li>Hydratační ošetření<label>1050,-Kč</label></li>
                <li>Mandlové ošetření<label>1050,-Kč</label></li>
                <li>Akné ošetření junior (do 20 let)<label>550,-Kč</label></li>
                <li>Ošetření kuperózy<label>1050,-Kč</label></li>
                <!-- <li>Liftingové ošetření<label>1150,-Kč</label></li> -->
                <li>Anti - Aging ošetření<label>1350,-Kč</label></li>
                <li>
                  Anti - Aging ošetření + mezoterapie (cca 90min)
                  <label>1450,-Kč</label>
                </li>
                <!-- <li>Chemický peeling (zimní)<label>1050,-Kč</label></li> -->
                <li>
                  Peelingové ošetření RX50 (celoroční)<label>1350,-Kč</label>
                </li>
                <li>
                  Peelingové ošetření Revinol (zimní)<label>1250,-Kč</label>
                </li>
                <li>
                  <strong>Novinka</strong> ošetření Rx Carboxy<label>1500,-Kč</label>
                </li>
                <li>
                  <strong>Novinka</strong> ošetření Rx Carboxy + mezoterapie<label>1750,-Kč</label>
                </li>
              </ul>
              <ul>
                <li>
                  Galvanická žehlička NuSkin (cca 30min)<label>450,-Kč</label>
                </li>
                <li>Ultrazvuková špachtle (cca 30min)<label>450,-Kč</label></li>
                <li>Parafín na ruce (cca 20min)<label>150,-Kč</label></li>
              </ul>
              <ul>
                <li>Barvení obočí<label>100,-Kč</label></li>
                <li>Úprava obočí<label>100,-Kč</label></li>
                <li>Úprava a barvení obočí<label>180,-Kč</label></li>
                <li>Úprava a barvení obočí (Senioři)<label>150,-Kč</label></li>
                <li>Úprava a barvení obočí (Studenti)<label>150,-Kč</label></li>
                <li>Laminace obočí<label>450,-Kč</label></li>
                <li>3D rekonstrukce obočí<label>550,-Kč</label></li>
                <li>Barvení řas<label>100,-Kč</label></li>
              </ul>
            </div>
            <div class="list-item">
              <h2>Depilace</h2>
              <ul>
                <li>Obličej (horní ret, brada, tváře, spánky)<label>350,-Kč</label></li>
                <li>Horní ret<label>90,-Kč</label></li>
                <li>Brada<label>90,-Kč</label></li>
                <li>Lýtka<label>350,-Kč</label></li>
                <li>Celé nohy<label>600,-Kč</label></li>
              </ul>
            </div>
            <div class="list-item">
              <h2>Masáže</h2>
              <ul>
                <li>
                  Klasicka,zdravotní - záda a šíje (30min)<label>350,-Kč</label>
                </li>
                <li>
                  Permanentka (záda a šíje) 5 vstupů<label>1250,-Kč</label>
                </li>
                <li>
                  Klasicka celková (záda,šíje,nohy,ruce) (60min)
                  <label>650,-Kč</label>
                </li>
                <li>Masáž lávovými kameny (30min)<label>550,-Kč</label></li>
                <li>Masáž lávovými kameny (60min)<label>850,-Kč</label></li>
                <li>
                  Lymfodrenáž ruční - krk,ruce,nohy (60min)
                  <label>750,-Kč</label>
                </li>
                <li>Balíček : 5x Lymfodrenáž (60min)<label>3000,-Kč</label></li>
                <li>
                  Lymfodrenáž ruční - krk,ruce,nohy,hlava,záda (90min)
                  <label>1050,-Kč</label>
                </li>
                <li>Balíček : 5x Lymfodrenáž (90min)<label>4500,-Kč</label></li>
                <!-- <li>
                  Reflexní terapie (plosky nohou) (30min)<label>350,-Kč</label>
                </li> -->
                <li>Kraniosakrální terapie (40min)<label>500,-Kč</label></li>
                <li>
                  Balíček : 4x Kraniosakrální terapie<label>1700,-Kč</label>
                </li>
              </ul>
              <!-- <h3>Lipo M6 (40min)</h3>
              <ul>
                <li>1 ošetření<label>1000,-Kč</label></li>
                <li>10 ošetření<label>7500,-Kč</label></li>
              </ul> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
