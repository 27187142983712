<template>
  <main>
    <section class="contact">
      <div class="container">
        <h1>Kontakt</h1>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <p>masáže + kosmetika</p>
            <p><strong>Zdenka Zelenková</strong></p>
            <p>
              <a href="tel:+420 607 937 934"
                >+420 607 937 934 <i class="bi bi-telephone"></i
              ></a>
            </p>
            <p>
              <a href="mailto:z.zelenkova66@gmail.com"
                >z.zelenkova66@gmail.com <i class="bi bi-envelope"></i
              ></a>
            </p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <span>Adresa</span>
            <p>Dr. Marodyho 912/10</p>
            <p>Praha 9 - Čakovice</p>
            <p>196 00</p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <span>Provozní doba</span>
            <p>Po - Pá</p>
            <p>dle objednávek</p>
          </div>
          <div class="col-md-12">
            <h2 class="tittle">Najdete nás zde</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d159.78465330195365!2d14.522142395267943!3d50.15076634084486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470bec67f4ce39ef%3A0x4ab3636f71068561!2sDr.%20Marodyho%20912%2C%20196%2000%20Praha-%C4%8Cakovice!5e0!3m2!1scs!2scz!4v1647886432607!5m2!1scs!2scz"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              title="mapa, kde najdete Finestudio Čakovice"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
