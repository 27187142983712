<template>
  <main>
    <section class="list">
      <div class="container">
        <h1>Nabídka služeb</h1>
        <div class="row">
          <div class="col-md-12 columned">
            <div class="list-item">
              <h2>Kosmetika</h2>
              <p>
                Kosmetické ošetření Natinuel - Nová dimenze salónní péče mění
                svět kosmetiky na „Kosmetickou chirurgii“. Interaktivita na více
                úrovních . Biotechnologie na úrovni Natinuel je schopna zajistit
                vzájemné a selektivní působení pro všechny strukturální změny na
                úrovni epidermis, dermis a hypodermis, které jsou základem
                každého neestetického projevu kůže.
              </p>
              <ul>
                <li>Galvanická žehlička</li>
                <li>Ultrazvuková špachtle</li>
                <li>Barvení řas a obočí</li>
                <li>Depilace v obličeji</li>
                <li>Depilace nohou</li>
              </ul>
            </div>
            <div class="list-item">
              <h2><strong>Novinka 2023</strong> Ošetření RXCarboxy</h2>
              <p>
                biointeraktivní neinvazivní ošetření vhodné pro všechny druhy
                pleti:
              </p>
              <ul>
                <li>Blokuje tvorbu zánětu</li>
                <li>Chrání buněčnou DNA</li>
                <li>Revitalizuje buněčnou aktivitu</li>
                <li>Podporuje regenerační procesy</li>
                <li>Stimuluje tvorbu kolagenu</li>
                <li>Omlazuje</li>
              </ul>
            </div>
            <div class="list-item">
              <h2>Masáže</h2>
              <p>
                Soustava masážních hmatů s léčebným zaměřením na jednotlivé
                části těla
              </p>
              <p>
                <strong>Fyziologické účinky</strong> – odstraňování
                zrohovatělých vrstev kůže, normalizace napětí kůže. Podporuje se
                vstřebávání otoků, výpotků a krevních výronů. Zvyšuje se tok
                mízy(lymfy), zlepšuje se svalová činnost a správnou volbou hmatů
                je možné dosáhnout snížení nebo zvýšení svalového napětí.
                <strong
                  >Působením všech účinků se snižuje bolestivost a zlepšuje se
                  činnost tkání</strong
                >.
              </p>
              <p>
                <strong>Lávové kameny( MAGIC HOT STONES THERAPY)</strong> – k
                této masáži se využívá lávových kamenů, které skvěle akumulují
                teplo , zbavují tělo únavy a nabíjí ho energií. Masáž, prováděna
                lávovými kameny, je pro tělo méně náročná, ale má silnější
                účinek oproti běžné masáži. Při této terapii se uvolňují některé
                anionty kovů , přispívající k urychlení regenerace tkání. Lávové
                kameny mají mimořádně silnou energetickou vibraci.
              </p>
              <h3>Terapie se používá</h3>
              <ul>
                <li>K odstranění blokací důležitých bodů</li>
                <li>Při stuhlosti svalů</li>
                <li>K prohřátí tkáně</li>
                <li>Ke stimulaci krevního a lymfatického systému</li>
              </ul>
              <h3>Masáž doporučujeme</h3>
              <ul>
                <li>Při problémech se spánkem</li>
                <li>Při problémech s koncentrací</li>
                <li>Podnikatelům, kteří jsou pod neustálým tlakem</li>
              </ul>
            </div>
            <!-- <div class="list-item">
              <h2>LPG - Lipo M6 - endermologie</h2>
              <p>
                <strong>Lipomasáže</strong> LPG -Ošetření přístrojem LIPO M6 -
                tzv. lipomasáž. LPG - patentovaný systém ošetření kožní a
                podkožní tkáně. Tato špičková technologie pomůže Vaši postavu
                zpevnit, tvarovat a zeštíhlit bez nutnosti operace.
              </p>
              <p>
                Podpořte Vaši změnu image pomocí patentovaného ošetření LPG,
                pravidelného cvičení , úpravou pitného režimu a jídelníčku za
                příznivou <router-link to="/cenik">cenu</router-link>
              </p>
              <h3>Terapie se používá</h3>
              <ul>
                <li>K odstranění blokací důležitých bodů</li>
                <li>Při stuhlosti svalů</li>
                <li>K prohřátí tkáně</li>
                <li>Ke stimulaci krevního a lymfatického systému</li>
              </ul>
              <h3>Masáž doporučujeme</h3>
              <ul>
                <li>Při problémech se spánkem</li>
                <li>Při problémech s koncentrací</li>
                <li>Podnikatelům, kteří jsou pod neustálým tlakem</li>
              </ul>
            </div> -->
            <!-- <div class="list-item">
              <h2>Infrasauna</h2>
              <p>
                Již osvědčený systém v termoterapii, založený na principu
                prohřívání lidského těla pomocí přenosu tepla infrazářením.Při
                této formě saunování se ohřívá nejen povrch těla, ale teplo
                proniká i do hloubky tkáně a dochází k rychlejšímu prohřátí
                požadovaných částí těla než při klasickém saunování.
              </p>
              <p>
                Výhodou je nižší okolní teplota (cca 20 - 55° C) v infrakabině a
                tím zpřístupění této terapie i lidem, kteří nemohou využívat
                klasické sauny (po konzultaci s lékařem astmatici, alergici,
                kardiaci).
              </p>
              <ul>
                <li>Zvyšuje rozpínavost tkáně</li>
                <li>Ihned snižuje ztuhlost končetin, kloubů a zad</li>
                <li>Snižuje svalové napětí</li>
                <li>Mírní bolesti</li>
                <li>Stimuluje krevní oběh</li>
                <li>
                  Příznivě působí při léčbě chorobných zánětlivých pocitů kůže
                </li>
                <li>Vhodný doplněk redukce váhy za 30min. 600 cal.</li>
                <li>Účinná relaxace a regenerace organismu</li>
              </ul>
            </div> -->
            <div class="list-item">
              <h2>Lymfodrenáž ruční</h2>
              <p>
                Jemná hmatová technika ovlivňující lymfatický systém. Má
                sedativní, anelgetický a spasmolytický efekt (zmírňuje posit
                napětí a bolestivosti)
              </p>
              <p>
                V USA se řáká této masáži "Butterfly kis" , tedy polibek
                motýlích křídel
              </p>
              <h3>Doporučuje se při:</h3>
              <ul>
                <li>Žilní a lymfatické otoky</li>
                <li>Postoperační a postraumatické stavy</li>
                <li>Bércový vřed</li>
                <li>Obezita, lipedém</li>
                <li>Migréna</li>
                <li>Morbus Bechtěrjev</li>
                <li>Sportovní medicína</li>
                <li>Kosmetika</li>
              </ul>
              <h3>Kontraindikace absolutní :</h3>
              <ul>
                <li>Neléčené nebo nedoléčené zhoubné onemocnění</li>
                <li>Akutní bakteriální nebo virové onemocnění</li>
                <li>Akutní horečnaté stavy</li>
                <li>Hnisavé kožní rány</li>
                <li>Otoky ledvinového, srdečního a jateního původu</li>
                <li>Onemocnění jater a ledvin</li>
              </ul>
              <h3>Kontraindikace relativní:</h3>
              <ul>
                <li>Bolestivá menstruace</li>
                <li>Těhotenství do 3. měsíce</li>
                <li>Vysoký TK</li>
                <li>Onemocnění štítné žlázy</li>
                <li>Asthma,chronická bronchitida - vždy se souhlasem lékaře</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
