<template>
  <main>
    <section class="gallery">
      <div class="container">
        <h1>Galerie</h1>
        <div class="row">
          <div class="col-md-3">
            <a
              href="assets/img/gallery/vchod2.webp"
              data-lightbox="gallery"
              data-title="ukázka interiéru studia 1"
              ><img
                class="example-image"
                src="assets/img/gallery/preview_vchod2.webp"
                alt="ukázka interiéru studia 1"
            /></a>
          </div>
          <div class="col-md-3">
            <a
              href="assets/img/gallery/detail1.webp"
              data-lightbox="gallery"
              data-title="ukázka interiéru studia 2"
              ><img
                class="example-image"
                src="assets/img/gallery/preview_detail1.webp"
                alt="ukázka interiéru studia 2"
            /></a>
          </div>
          <div class="col-md-3">
            <a
              href="assets/img/gallery/masaze1.webp"
              data-lightbox="gallery"
              data-title="FINESTUDIO Čakovice"
              ><img
                class="example-image"
                src="assets/img/gallery/preview_masaze1.webp"
                alt="ukázka interiéru studia 3"
            /></a>
          </div>
          <div class="col-md-3">
            <a
              href="assets/img/gallery/recepce1.webp"
              data-lightbox="gallery"
              data-title="ukázka interiéru studia 4"
              ><img
                class="example-image"
                src="assets/img/gallery/preview_recepce1.webp"
                alt="ukázka interiéru studia 4"
            /></a>
          </div>
          <div class="col-md-3">
            <a
              href="assets/img/gallery/detail2.webp"
              data-lightbox="gallery"
              data-title="ukázka interiéru studia 5"
              ><img
                class="example-image"
                src="assets/img/gallery/preview_detail2.webp"
                alt="ukázka interiéru studia 5"
            /></a>
          </div>
          <div class="col-md-3">
            <a
              href="assets/img/gallery/masaze2.webp"
              data-lightbox="gallery"
              data-title="ukázka interiéru studia 6"
              ><img
                class="example-image"
                src="assets/img/gallery/preview_masaze2.webp"
                alt="ukázka interiéru studia 6"
            /></a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
