<template>
  <header>
    <div class="container">
      <nav class="navbar navbar-expand-lg static-top">
        <router-link to="/" class="navbar-brand"
          >Finestudio <label>Čakovice</label></router-link
        >
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto navigation">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Úvod</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/sluzby" class="nav-link">Služby</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/cenik" class="nav-link">Ceník</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/galerie" class="nav-link">Galerie</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/kontakt" class="nav-link">Kontakt</router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>

  <router-view />

  <footer>
    <div class="container">
      <p>
        &copy; {{ this.year }}
        <a
          href="https://zelenkamartin.cz/"
          target="_blank"
          aria-label="odkaz je externí a otevře se v novém okně"
          >Martin Zelenka <i class="bi bi-box-arrow-up-right"></i
        ></a>
        | Finestudio Čakovice, Všechna práva vyhrazena.
      </p>
    </div>
  </footer>
</template>

<script>
import moment from "moment";

export default {
  name: "App",
  data() {
    return {
      year: moment().format("YYYY"),
    };
  },
};
</script>
